import { createApp } from 'vue'
import App from './App.vue'
import './assets/base.css'
import 'amfe-flexible'
import router from './router/index'

// 按需引入后不需要
// needed components
// import { Button } from 'vant'
// style
// import 'vant/lib/index.css' 

const app = createApp(App)
app.use(router)
app.mount('#app')