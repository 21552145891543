import { createRouter, createWebHashHistory } from "vue-router";

const router = createRouter({
    history: createWebHashHistory(),
    routes: [
        {
            path: "/",
            component: () => import("../views/home/home.vue")
        },
        {
            path: "/home",
            component: () => import("../views/home/home.vue")
        },
        {
            path: "/courses",
            component: () => import("../views/courses/courses.vue")
        },
        {
            path: "/sheets",
            component: () => import("../views/sheets/sheets.vue")
        },
        {
            path: "/mine",
            component: () => import("../views/mine/mine.vue")
        }
    ]
});

export default router;